import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const pageTitles = {
    // Auth
    '/auth/inicio-sesion': { title: 'Web de Administración' },
    '/auth/registro': { title: 'Registro' },
    '/auth/recuperar-contraseña': { title: 'Recuperación de Contraseña' },
    '/auth/confirmar-correo': { title: 'Confirmación de Correo Electrónico' },
    // Inicio
    '/inicio': { title: 'Inicio' },
    // Afiliados
    '/clientes': {
        title: 'Clientes',
        // description: 'Consulte y edite la información de clientes.'
    },
    // Planes
    '/planes': {
        title: 'Planes',
        // description: 'Consulte y edite la información de planes.'
    },
    // Usuarios
    '/usuarios': {
        title: 'Usuarios',
        // description: 'Consulte y edite la información de usuarios.'
    },
    // Configuración
    '/administracion/configuracion': {
        title: 'Configuración',
        // description: 'Consulte y edite la configuración del sistema.'
    }
};

@Injectable()
export class HeaderService {

    params: Array<string>;
    private titleSource = new BehaviorSubject({ title: null, description: null });
    title = this.titleSource.asObservable();

    constructor(private router: Router) {
        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                this.params = route.url.split('/');

                if (!isNaN(+this.params[this.params.length - 1])) {
                    route.url = route.url.slice(0, route.url.lastIndexOf('/'));
                }

                this.titleSource.next(pageTitles[route.url]);
            }
        })
    }

    addReportHeader(items: Array<any>) {
        items.forEach(item => {
            pageTitles[`/reportes/${item.name}`] = {
                title: item.description,
                // description: null
            };
        });
    }

    addLogHeader(items: Array<any>) {
        items.forEach(item => {
            pageTitles[`/bitacoras/${item.name}`] = {
                title: item.description,
                // description: null
            };
        });
    }
}
