export enum UserFunctions {
    
    CUSTOMERS = 'P_CUSTOMERS',
    CUSTOMER_ADD = 'P_CUSTOMER_ADD',
    CUSTOMER_EDIT = 'P_CUSTOMER_EDIT',
    CUSTOMER_DETAIL = 'P_CUSTOMER_DETAIL',
    CUSTOMER_DELETE = 'P_CUSTOMER_DELETE',
    
    PLANS = 'P_PLANS',
    PLAN_ADD = 'P_PLAN_ADD',
    PLAN_EDIT = 'P_PLAN_EDIT',
    PLAN_DETAIL = 'P_PLAN_DETAIL',
    PLAN_DELETE = 'P_PLAN_DELETE',

    USER_ADD = "P_USER_ADD",
    USER_EDIT = "P_USER_EDIT",
    USER_DELETE = "P_USER_DELETE",
    USER_DETAIL = "P_USER_DETAIL",
    USERS = "P_USERS",

    CONFIGURATIONS = "P_CONFIGURATIONS",
    CONFIGURATION_EDIT = "P_CONFIGURATION_EDIT",
    CONFIGURATION_DETAIL = "P_CONFIGURATION_DETAIL",

    DYNAMICREPORT_EXPORT = "P_DYNAMICREPORT_EXPORT",
    DYNAMICREPORTS = "P_DYNAMICREPORTS",

}
