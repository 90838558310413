<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header" [ngClass]="{ 'pl-0': !loggedIn }">
        <a class="navbar-brand" [routerLink]="['inicio']">
            <div class="brand-logo">
                <img class="img-fluid" src="assets\images\BCS.logo-white.png" alt="BCS EX-cle" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets\images\ec-white-logo.png" alt="BCS Ex-Cle" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li *ngIf="loggedIn" class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block pointer" trigger-resize=""
                (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none pointer"
                (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
        <!-- START title-->
        <li *ngIf="title !== '' && title !== undefined" class="nav-item ml-3 d-none d-md-block">
                <h2>
                    {{title}}
                </h2>
        </li>
        <!-- END title-->
        <!-- START description-->
        <li *ngIf="description !== '' && description !== undefined" class="nav-item d-none d-md-block">
            <a class="nav-link" placement="auto" tooltip="{{description}}"><em
                class="fa-lg fas fa-info-circle"></em></a>
        </li>
        <!-- END description-->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
   <ul class="navbar-nav flex-row align-items-center">
        <!-- Fullscreen (only desktops)-->
        <!-- <li class="nav-item d-md-block" *ngIf="!loggedIn">
            <a class="nav-link pointer py-2 px-1" #fsbutton (click)="redirectToLogin()">
                <button class="btn btn-lg btn-outline-light mx-2">Inicio</button>
            </a>
        </li> -->
        <li class="nav-item d-none d-md-block">
            <a class="nav-link pointer align-self-middle" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>
        <li class="nav-item d-none d-md-block ml-2">
            <a class="nav-link pointer align-self-middle p-0">
                <img src="assets\images\ec-white-logo.png" alt="BCSEx-Cle" />
            </a>
        </li>
        <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link pointer align-self-middle p-0">
                <img class="w-75" src="assets\images\bdv2-white-isologo.png" alt="BCS Ex-Cle" />
            </a>
        </li> -->
    </ul>
    <!-- END Right Navbar-->

    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>

</nav>
<!-- END Top Navbar-->
