import { UserFunctions } from './user-functions';

export class User {
    userName: string;
    role?: Role;
    tokens: AuthenticationToken
}

export class Role {
    id: number;
    name?: string;
    functions?: string[] = [];
}

export class Token {
    unique_name: string;
    role: string;
    Function: string | string[];
    EmailConfirmed: string;
    nbf: number;
    exp: number;
    iat: number;
}

export class AuthenticationToken {
    accessToken: string;
    refreshToken: string;
}

export class RefreshToken {
    accessToken: string;
    refreshToken: string;
}
