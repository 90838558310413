import { LayoutComponent } from '../modules/layout/layout.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'auth', loadChildren: () => import('../modules/auth/auth.module').then(mod => mod.AuthModule)
            },
            {
                path: 'inicio', loadChildren: () => import('../modules/home/home.module').then(mod => mod.HomeModule)
            },
            // Afiliados
            {
                path: 'clientes', loadChildren: () => import('../modules/customers/customers.module').then(mod => mod.CustomersModule)
            },
            // Planes
            {
                path: 'planes', loadChildren: () => import('../modules/plans/plans.module').then(mod => mod.PlansModule)
            },
            // Usuarios
            {
                path: 'usuarios', loadChildren: () => import('../modules/user/user.module').then(mod => mod.UserModule)
            },
            // Reportes Dinámicos
            {
                path: 'reportes/:name', loadChildren: () => import('../modules/reports/dynamic-reports.module').then(mod => mod.DynamicReportsModule)
            },
            // Configuracion
            {
                path: 'administracion', loadChildren: () => import('../modules/administration/admin.module').then(mod => mod.AdminModule)
            },
            // default
            { path: '', redirectTo: 'inicio', pathMatch: 'full' },
        ]
    },

    // Not found
    { path: '**', redirectTo: 'inicio' }

];
